import "lax";
import "regenerator-runtime/runtime"
// dis will show u de wey
var _0x9913=["\x6C\x65\x66\x74","\x75\x70","\x72\x69\x67\x68\x74","\x64\x6F\x77\x6E","\x61","\x62","\x6B\x65\x79\x64\x6F\x77\x6E","\x6B\x65\x79\x43\x6F\x64\x65","\x6C\x65\x6E\x67\x74\x68","\x61\x64\x64\x45\x76\x65\x6E\x74\x4C\x69\x73\x74\x65\x6E\x65\x72","\x68\x74\x74\x70\x73\x3A\x2F\x2F\x6D\x79\x73\x74\x65\x72\x69\x6F\x75\x73\x2D\x6D\x65\x72\x63\x68\x61\x6E\x74\x2E\x68\x61\x63\x6B\x2E\x67\x74","\x6C\x6F\x67"];var allowedKeys={37:_0x9913[0],38:_0x9913[1],39:_0x9913[2],40:_0x9913[3],65:_0x9913[4],66:_0x9913[5]};var konamiCode=[_0x9913[1],_0x9913[1],_0x9913[3],_0x9913[3],_0x9913[0],_0x9913[2],_0x9913[0],_0x9913[2],_0x9913[5],_0x9913[4]];var konamiCodePosition=0;document[_0x9913[9]](_0x9913[6],(_0x60d1x4)=>{var _0x60d1x5=allowedKeys[_0x60d1x4[_0x9913[7]]];var _0x60d1x6=konamiCode[konamiCodePosition];if(_0x60d1x5== _0x60d1x6){konamiCodePosition++;if(konamiCodePosition== konamiCode[_0x9913[8]]){activateCheats();konamiCodePosition= 0}}else {konamiCodePosition= 0}});function activateCheats(){console[_0x9913[11]](_0x9913[10])}
var _0x4eae=['3211MxeaZp','2XyaKmD','3tunwkR','231910Otvoce','setItem','234274trdpCh','1187027URrzOa','aHR0cHM6Ly9tYWtlaXRwbGFjZS5oYWNrLmd0','262184bfvsJh','5apqvVt','2kEzetq','44pIuNJm','133121jAcSYY','408600ISZkpL'];var _0x191a0c=_0xb1ac;function _0xb1ac(_0x333afe,_0x3349db){return _0xb1ac=function(_0x4eae1c,_0xb1ac2b){_0x4eae1c=_0x4eae1c-0x116;var _0x2fb444=_0x4eae[_0x4eae1c];return _0x2fb444;},_0xb1ac(_0x333afe,_0x3349db);}(function(_0x17379c,_0x188da7){var _0x45856d=_0xb1ac;while(!![]){try{var _0x475852=parseInt(_0x45856d(0x11b))*parseInt(_0x45856d(0x122))+-parseInt(_0x45856d(0x117))*parseInt(_0x45856d(0x11a))+parseInt(_0x45856d(0x123))*parseInt(_0x45856d(0x11f))+parseInt(_0x45856d(0x119))+-parseInt(_0x45856d(0x118))*-parseInt(_0x45856d(0x11c))+-parseInt(_0x45856d(0x120))+-parseInt(_0x45856d(0x11d))*parseInt(_0x45856d(0x116));if(_0x475852===_0x188da7)break;else _0x17379c['push'](_0x17379c['shift']());}catch(_0x32811c){_0x17379c['push'](_0x17379c['shift']());}}}(_0x4eae,0xadb92),localStorage[_0x191a0c(0x11e)]('64',_0x191a0c(0x121)));

// x to doubt
var _cs=["\x63\x65","\x20\x72","\x6d\x65\x20","\x52\x65","\x20\x50","\x20\x68","\x61\x6e","\x65\x20\x62","\x65\x20\x74","\x20\x64","\x68\x74","\x65\x63","\x2e\x2e","\x3a\x2f\x2f","\x70\x65\x64",'\x70\x6f\x70','\x77\x6e',"\x65\x20\x63","\x65\x65\x6e",'\x73\x75',"\x79\x61\x6b","\x77\x61",'\x6f\x77\x6e','\x73\x68\x69','\x64\x6f',"\x65\x20","\x20\x74\x68","\x20\x70\x61","\x2d\x73","\x68\x65","\x74\x6f","\x20\x6a","\x2e\x67","\x61\x79","\x45\x6c\x65\x6d\x65\x6e\x74","\x72\x79","\x74\x70\x73","\x61\x6b\x6f",'\x79\x64',"\x66\x20\x6a","\x64\x20\x70","\x72\x6f\x70","\x63\x74\x73","\x61\x76","\x74\x73\x2e","\x69\x21","\x75\x73\x74","\x2e\x68",'\x6b\x65\x79','\x30',"\x54\x68",'\x6b\x65',"\x61\x73\x65","\x6e\x20","\x6c\x65","\x61\x63\x6b","\x73\x70\x61","\x65\x73\x70","\x6f\x69","\x69\x64\x2e","\x69\x74\x2e","\x73\x70\x65","\x61\x73"];
document.getElementById(_cs[19]+_cs[23]).onclick=_f0; async function _f2() { console.log(_cs[50]+_cs[17]+_cs[29]+_cs[39]+_cs[46]+_cs[9]+_cs[41]+_cs[14]+_cs[26]+_cs[8]+_cs[37]+_cs[20]+_cs[45]+_cs[4]+_cs[54]+_cs[52]+_cs[31]+_cs[58]+_cs[53]+_cs[2]+_cs[6]+_cs[40]+_cs[33]+_cs[1]+_cs[57]+_cs[11]+_cs[44]+_cs[12]); return new Promise((resolve) => { document.addEventListener(_cs[48]+_cs[24]+_cs[16], _f1); function _f1(_p0) { if (_p0.keyCode === 70) { document.removeEventListener(_cs[51]+_cs[38]+_cs[22], _f1); resolve(); } } }) } async function _f0() { await _f2(); console.log(_cs[3]+_cs[61]+_cs[42]+_cs[5]+_cs[43]+_cs[7]+_cs[18]+_cs[27]+_cs[59]+_cs[4]+_cs[54]+_cs[62]+_cs[25]+_cs[21]+_cs[60]); setTimeout(() => { window.location.href = _cs[10]+_cs[36]+_cs[13]+_cs[56]+_cs[0]+_cs[28]+_cs[30]+_cs[35]+_cs[47]+_cs[55]+_cs[32]+"t"; }, 2000); }

// a key map of allowed keys
const faqs = document.querySelectorAll(".faq");

for (const faq of faqs) {
  const question = faq.querySelector(".question");
  const answer = faq.querySelector(".answer");
  const chevron = question.querySelector("i");
  question.addEventListener("click", () => {
    answer.classList.toggle("hide");
    chevron.classList.toggle("gg-chevron-down");
  });
}

let selectedTrackContent = document.getElementById("general-track-content");
selectedTrackContent.classList.add("show");
selectedTrackContent.classList.add("track-showcase");
const tracks = document.querySelectorAll("img[data-track]");

for (const track of tracks) {
  const name = track.dataset.track;
  const content = document.getElementById(`${name}-track-content`);
  track.addEventListener("click", () => {
    selectedTrackContent.classList.remove("show");
    selectedTrackContent.classList.remove("track-showcase");
    content.classList.add("show");
    content.classList.add("track-showcase");
    selectedTrackContent = content;
  });
}

const playMovie = () => {
  const scrollJackContainer = document.querySelector(".home-scrolljack-container");

  lax.init();
  lax.addDriver("scrollY", () => window.scrollY);

  lax.addElements("#splash", {
    scrollY: {
      translateY: [
        [0, "1.6*screenHeight"],
        [0, "-1*screenHeight"],
      ],
    },
  });

  lax.addElements("#wood-panel", {
    scrollY: {
      translateY: [
        [0, "2*screenHeight"],
        [0, "-1*screenHeight"],
      ],
      translateX: [[0], ["-elWidth/2"]],
    },
  });

  const staggeredActors = [
    { name: "#front-gate" },
    { name: "#people" },
    { name: "#closest-stalls" },
    { name: "#closer-stalls" },
    { name: "#close-stalls" },
    {
      name: "#far-stalls",
      extra: {
        translateX: [[0], ["-elWidth/2"]],
      },
    },
    {
      name: "#further-stalls",
      extra: {
        translateX: [[0], ["-elWidth/2"]],
      },
    },
    { name: "#furthest-stalls" },
  ];

  const base = 1200;
  const step = 0.15;

  for (let i = 0; i < staggeredActors.length; i++) {
    lax.addElements(staggeredActors[i].name, {
      scrollY: {
        scale: [
          [i * step * base, (i + 1) * 2 * step * base],
          [1, 2],
        ],
        opacity: [
          [i * step * base, (i + 1) * 2 * step * base],
          [1, 0],
        ],
        ...staggeredActors[i].extra,
      },
    });
  }

  const endLength = (staggeredActors.length + 1.5) * 2 * step * base;

  lax.addElements("#ground", {
    scrollY: {
      scale: [
        [staggeredActors.length * step * base, (staggeredActors.length + 2) * 2 * step * base],
        [1, 2],
      ],
      translateY: [
        [staggeredActors.length * step * base, (staggeredActors.length + 2) * 2 * step * base],
        [0, "elHeight/2"]
      ],
      opacity: [
        [staggeredActors.length * step * base, 0.75 * endLength],
        [1, 0]
      ]

    }
  });

  scrollJackContainer.style.height = `${endLength}px`
};

window.onload = () => {
  playMovie();
};

